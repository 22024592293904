import * as React from "react";
import type { HeadFC } from "gatsby";
import { useInView } from "react-intersection-observer";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import { useEffect, useState } from "react";
import { getSuggestion } from "../components/suggestion/suggestion";
import logomark from "../images/logomark.svg";
import beanbag from "../images/beanbag.svg";
import phone from "../images/signupphone.png";
import confirmation from "../images/confirmation.png";

const PhoneImage = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        fallbackInView: true
    });
    return (
        <div
            ref={ref}
            className={"flex w-full justify-center pb-24 md:justify-end md:pb-0 md:pt-24 lg:justify-center lg:pt-0"}>
            <img
                ref={ref}
                data-cypress={"phone"}
                src={phone}
                className={`max-w-full ${
                    inView ? "translate-x-0" : "translate-x-[200%]"
                } w-3/5 rotate-2 transition-transform delay-500 duration-500 dark:opacity-90 md:w-3/4 lg:ml-[20%] lg:w-1/2 xl:w-2/5`}
                alt={"Phone with a signup screen"}
            />
        </div>
    );
};

const MailImage = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        fallbackInView: true,
        threshold: 0.8
    });
    return (
        <div ref={ref}>
            <div
                className={"pt-24 dark:opacity-90 md:flex md:justify-end lg:justify-center"}
                style={{
                    transform: "perspective(800px) rotateY(320deg) rotateX(30deg) rotateZ(3deg)"
                }}>
                <img
                    src={confirmation}
                    className={`howitworksmail md:w-3/4 lg:w-1/2 xl:w-3/5 ${
                        inView
                            ? "translate-x-0 translate-y-0 scale-100 opacity-100"
                            : "translate-x-32 translate-y-4 scale-50 opacity-0"
                    } transition-all delay-500 duration-1000`}
                    alt={"Confirmation email"}
                />
            </div>
        </div>
    );
};

const HowItWorksPage = () => {
    const [suggestion, setSuggestion] = useState("");
    useEffect(() => {
        setSuggestion(getSuggestion());
    }, []);
    return (
        <div className={"overflow-x-hidden"}>
            <Navbar />
            <main className={"relative z-0 dark:text-stone-50/75"}>
                <div className={"px-8 pt-16 pb-24 md:container md:mx-auto"}>
                    <h1 className={"text-4xl font-bold text-stone-800 dark:text-stone-300 md:text-6xl"}>
                        How does Maildrop work?
                    </h1>
                    <div className={"flex flex-col pt-16 md:flex-row md:pt-24"}>
                        <div className={"order-2 flex-1 md:order-1 md:basis-[50ch]"}>
                            <h2 className={"pb-8 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                                "Please enter your email address."
                            </h2>
                            <p className={"pb-8"}>
                                What's your default reaction when you see that statement in a page, or an app, or
                                someone asks you that question? For a large amount of people, a sudden feeling of
                                unease, dread, or mistrust immediately washes over them, and for good reason. Email,
                                once a valued communication medium online, is now almost entirely dominated by spam,
                                marketing junk, and unimportant messages that do nothing but waste your time.
                            </p>
                            <p className={"pb-24"}>
                                These days, giving out your email address is a very personal act, as it implies a level
                                of trust that often times you're not willing to give to any random website or app.
                                You're entrusting the site, or app, or company, not to sell or give out your address.
                                You're also trusting their security, that they won't get hacked, or otherwise lose your
                                personal info.
                            </p>
                            <div className={"flex justify-center"}>
                                <img
                                    src={logomark}
                                    className={"w-12 opacity-10 dark:opacity-10 dark:invert"}
                                    alt={"Maildrop logo"}
                                />
                            </div>
                            <h2
                                className={
                                    "pb-8 pt-24 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"
                                }>
                                Enter Maildrop to the rescue.
                            </h2>
                            <p className={"pb-8"}>
                                Maildrop is a free throwaway email address. It's temporary. It's transient. It's
                                disposable. It's meant for those times when you don't want to give out your real
                                address. Just give someone any email address in the maildrop.cc domain, come back here,
                                put in the email address, and you can see that inbox.
                            </p>
                            <p className={"flex items-start pb-4"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-rose-500"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>Maildrop has no signups.</span>
                            </p>
                            <p className={"flex items-start pb-4"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-rose-500"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>Maildrop has no passwords.</span>
                            </p>
                            <p className={"flex items-start pb-4"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-rose-500"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>Maildrop is designed for no security.</span>
                            </p>
                            <p className={"flex items-start pb-8"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-rose-500"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>Maildrop is designed for little to no privacy.</span>
                            </p>
                            <p className={"pb-8"}>
                                Maildrop offers the ability to give out a quick email address to any site or app, then
                                after you've established more trust with that site, you can give them your real email
                                address.
                            </p>
                            <p className={"pb-8"}>
                                Maildrop helps to stop your inbox from getting flooded with spam from that one time you
                                registered on a site which got hacked.
                            </p>
                            <p className={"pb-8"}>
                                Maildrop can be used to get your receipt for your ecommerce purchase, without signing up
                                to be spammed on a regular basis with "latest offers".
                            </p>
                            <p className={"flex items-start"}>
                                <span className={"mt-0.5 mr-2.5 inline-block text-lime-500"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>
                                    In short, Maildrop can help you cut out the amount of junk in your inbox.
                                </span>
                            </p>
                        </div>
                        <div className={"order-1 flex-1 md:order-2 md:basis-1/2"}>
                            <PhoneImage />
                        </div>
                    </div>
                </div>
                <div className={"w-full overflow-hidden bg-stone-200 dark:bg-stone-800"}>
                    <div className={"h-16 w-full -translate-y-8 rotate-2 scale-110 bg-stone-50 dark:bg-stone-900"}>
                        &nbsp;
                    </div>
                </div>
                <div className={"bg-gradient-to-b from-stone-200 to-stone-50 dark:from-stone-800 dark:to-stone-900"}>
                    <div className={"px-8 pt-24 md:container md:mx-auto md:flex"}>
                        <div className={"md:flex-1 md:basis-[50ch]"}>
                            <h2 className={"pb-8 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                                What kind of email does Maildrop accept?
                            </h2>
                            <p className={"pb-8"}>
                                Maildrop is extremely strict about the content of messages allowed into inboxes. Plain
                                text or HTML emails are allowed, but must be less than 500k in length. All attachments
                                in messages are removed and discarded - this means no sending files to an inbox.
                            </p>

                            <p className={"pb-8"}>
                                An inbox can hold at most 10 messages, and any inbox which does not receive a message
                                within 24 hours will be automatically cleared. Additionally, there is a finite amount of
                                space to store all of the messages in all Maildrop inboxes, so any particular inbox that
                                has not recently received a message may be cleared to make room for more active inboxes.
                            </p>

                            <p className={"pb-24"}>
                                Content-wise, Maildrop does not allow any email messages having to do with any illegal
                                activities in your country, state, city, or region. Please check with your local law
                                enforcement agency for more information.
                            </p>
                            <div className={"flex justify-center"}>
                                <img
                                    src={logomark}
                                    className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                    alt={"Maildrop logo"}
                                />
                            </div>
                            <h2
                                className={
                                    "pb-8 pt-24 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"
                                }>
                                Maildrop also blocks spam.
                            </h2>
                            <p className={"pb-8"}>
                                Before a message makes its way into an inbox, it needs to get through multiple spam
                                filters. These filters are proven to reduce the overall amount of spam by more than 90%,
                                so Maildrop inboxes won't be flooded by junk email. Here are some of the filters that
                                messages are subjected to:
                            </p>

                            <p className={"flex items-start pb-8"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-amber-500 dark:text-amber-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>
                                    Email servers are checked to ensure that they aren't flooding Maildrop with
                                    connections, or have bad spam reputations, or are listed on any of multiple
                                    network-based blacklists.
                                </span>
                            </p>

                            <p className={"flex items-start pb-8"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-amber-500 dark:text-amber-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>
                                    From addresses are checked to ensure that messages from a domain are coming from
                                    valid email servers in that domain.
                                </span>
                            </p>

                            <p className={"flex items-start pb-8"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-amber-500 dark:text-amber-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            fillRule="evenodd"
                                            d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>
                                    All connections are then greylisted, meaning that only valid email servers are
                                    allowed to deliver messages to Maildrop inboxes.
                                </span>
                            </p>

                            <p className={"flex items-start pb-8"}>
                                <span className={"mr-2.5 mt-0.5 inline-block text-amber-500 dark:text-amber-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>
                                    Finally, message subjects are checked to make sure Maildrop isn't getting bombarded
                                    by the same message over and over again.
                                </span>
                            </p>

                            <p className={"flex items-start"}>
                                <span className={"mt-0.5 mr-2.5 inline-block text-lime-500 dark:text-lime-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>
                                    Only after a message passes through all of these filters will it be delivered to an
                                    inbox. Even though Maildrop may get a huge amount of attempted connections, nearly
                                    all spam messages to Maildrop will be rejected.
                                </span>
                            </p>
                        </div>
                        <div className={"hidden md:block md:flex-1 md:basis-1/2"}>
                            <MailImage />
                        </div>
                    </div>
                    <div className={"px-8 pt-24 pb-24 md:container md:mx-auto md:flex"}>
                        <div className={"md:flex-1 md:basis-[50ch]"}>
                            <div className={"flex justify-center"}>
                                <img
                                    src={logomark}
                                    className={"w-12 opacity-10 dark:opacity-10 dark:invert"}
                                    alt={"Maildrop logo"}
                                />
                            </div>
                        </div>
                        <div className={"md:flex-1 md:basis-1/2"}>&nbsp;</div>
                    </div>
                    <div className={"px-8 pb-32 md:container md:mx-auto md:flex"}>
                        <div className={"md:flex-1 md:basis-[50ch]"}>
                            <h2
                                className={
                                    "inline-block bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text pb-8 text-4xl font-bold text-transparent"
                                }>
                                Try Maildrop out - you'll love it!
                            </h2>
                            <p className={"pb-8"}>
                                The next time a web form or app asks you to "please enter your email address" you'll be
                                ready. "No problem, it's whatever@maildrop.cc." When that site sells your email address,
                                you can shrug, move onto another disposable Maildrop address, and your real email won't
                                get filled with junk mail.
                            </p>
                            <p className={"pb-8"}>
                                Need some help picking the perfect address? A good strategy is to include the name of
                                the site or app in the address itself, that way you know immediately which sites are
                                suspect with regards to your privacy.
                            </p>
                            <p className={"pb-4"}>For example:</p>
                            <div className={"flex items-start"}>
                                <span className={"mt-0.5 mr-2.5 inline-block text-lime-500 dark:text-lime-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>{suggestion}.SiteA@maildrop.cc</span>
                            </div>
                            <div className={"flex items-start"}>
                                <span className={"mt-0.5 mr-2.5 inline-block text-lime-500 dark:text-lime-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>{suggestion}.AppA@maildrop.cc</span>
                            </div>
                            <div className={"flex items-start"}>
                                <span className={"mt-0.5 mr-2.5 inline-block text-lime-500 dark:text-lime-400"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="h-5 w-5">
                                        <path
                                            strokeLinecap="round"
                                            d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                                        />
                                    </svg>
                                </span>
                                <span className={"inline-block"}>{suggestion}.SiteB@maildrop.cc</span>
                            </div>
                            <span className={"inline-block pt-4"}>and so on.</span>
                        </div>
                        <div className={"pt-16 md:flex-1 md:basis-1/2 md:pt-32 lg:pt-16"}>
                            <div className={"w-full md:flex md:justify-end lg:justify-center"}>
                                <img
                                    data-cypress={"beanbag"}
                                    src={beanbag}
                                    className={"dark:opacity-90 md:w-4/5"}
                                    alt={"Woman sitting with a laptop"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export const Head: HeadFC = () => (
    <>
        <title>How It Works | Maildrop</title>
    </>
);

export default HowItWorksPage;
